import React, {Component} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';

require('./scss/style.scss');
require('./scss/customSelect.scss');

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const colors = {
  nova: {
    colorPrimary: '#198b54',
    colorSecondary: '#0a6238',
    colorThird: '#084d2c',
    colorButtonPrimary: '#fde796',
    colorButtonPrimaryStrong: '#fcd64b',
    colorButtonPrimaryText: '#4f5d73'
  },
  solucao: {
    colorPrimary: '#0079b8',
    colorSecondary: '#006fa9',
    colorThird: '#004569',
    colorButtonPrimary: '#25ac70',
    colorButtonPrimaryStrong: '#069858',
    colorButtonPrimaryText: '#ffffff'
  },
  opcao: {
    colorPrimary: '#395187',
    colorSecondary: '#334979',
    colorThird: '#183164',
    colorButtonPrimary: '#2db95c',
    colorButtonPrimaryStrong: '#16bb52',
    colorButtonPrimaryText: '#ffffff'
  },
  maxi: {
    colorPrimary: '#0f4b87',
    colorSecondary: '#144373',
    colorThird: '#183164',
    colorButtonPrimary: '#5eb700',
    colorButtonPrimaryStrong: '#54a401',
    colorButtonPrimaryText: '#ffffff'
  }
}

const App = () => {

  React.useEffect(() => {
    let whiteLabelLogo = process.env.REACT_APP_WHITE_LABEL ?? 'nova';
    const root = document.documentElement;
    // eslint-disable-next-line no-unused-expressions
    root?.style.setProperty("--color-primary", colors[whiteLabelLogo].colorPrimary);
    // eslint-disable-next-line no-unused-expressions
    root?.style.setProperty("--color-secondary", colors[whiteLabelLogo].colorSecondary);
    // eslint-disable-next-line no-unused-expressions
    root?.style.setProperty("--color-third", colors[whiteLabelLogo].colorThird);
    // eslint-disable-next-line no-unused-expressions
    root?.style.setProperty("--color-button-primary", colors[whiteLabelLogo].colorButtonPrimary);
    // eslint-disable-next-line no-unused-expressions
    root?.style.setProperty("--color-button-primary-strong", colors[whiteLabelLogo].colorButtonPrimaryStrong);
    // eslint-disable-next-line no-unused-expressions
    root?.style.setProperty("--color-button-primary-text", colors[whiteLabelLogo].colorButtonPrimaryText);
  }, []);

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>}/>
          <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>}/>
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>}/>
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>}/>
          <Route path="/" name="Home" render={props => <TheLayout {...props}/>}/>
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
}

export default App;
